import i18n from 'i18next';
import { initReactI18next, Translation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
 import translationIT from "../src/locales/it/translation.json";
 import translationDE from "../src/locales/de/translation.json";


const resources = {
  de: {
    translation: translationDE
  },
  it: {
    translation: translationIT
  }
};


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    fallbackLng: 'de-DE',
    ignoreJSONStructure : false,
  });
export default i18n;