import { t } from 'i18next';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {ReactComponent as LinkIcon} from '../../icon/link-btn-black.svg';
import {ReactComponent as LocationIcon} from '../../icon/location.svg';
import {ReactComponent as DeptIcon} from '../../icon/dept.svg';
import { useEffect } from 'react';
import "./OverviewSection.css";

//Function for Dropdown
const DropdownSection = ({ jobListings }) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const [selectedJobId, setSelectedJobId] = useState(-1);
    const [selectedOption, setSelectedOption] = useState('');

    const toggleDropdown = (jobId) => {
        if (selectedJobId === jobId) {
            setIsOpen(prevState => !prevState);
        } else {
            setIsOpen(true);
            setSelectedJobId(jobId);
        }
    };

    const handleOptionSelect = (description) => {
        setSelectedOption(description);
        setIsOpen(false);
    };
    const { i18n } = useTranslation();

    useEffect(() => {
        const language = i18n.language;
        console.log(`Current language: ${language}`);
    }, [i18n.language]);

    return (
        <div className='flex flex-col items-center px-5 md:py-3 md:w-full'>
            <div className='flex flex-row justify-between items-center'>
                <div  className='pb-2 cursor-pointer'>
                    { jobListings.map((job, index) => {
                        const jobListingLocation = job.details.find(detail => detail.type === "Locations")?.text;
                        const jobListingDepartment = job.details.find(detail => detail.type === "Department")?.text;

                        return (
                            <>
                                <div className={`flex flex-row justify-between items-center border-b-2 px-3 ${isOpen && (job.id === selectedJobId) ? 'active' : ''}`} key={index} style={{ transition: 'all .35s ease-out' }}>
                                    <span onClick={() => toggleDropdown(job.id)} className='flex flex-wrap flex-row py-2 pr-2 items-center justify-between w-full' style={{ transition: 'all .75s ease-out' }}>
                                        <div className='flex flex-row md:w-3/5'>
                                            <p className='flex-wrap text-left text-sm md:text-xl font-semibold opacity-60 pr-4'>{job.id}</p>
                                            <p className='flex-wrap text-left font-semibold md:text-xl text-sm pr-1 truncate max-w-60 md:max-w-xl lg:min-w-96'>{job.title}</p>
                                        </div>
                                        <div className='flex flex-row py-2 md:py-2 lg:py-0 lg:w-2/5 w-full'>
                                            {jobListingLocation && <span className='flex flex-row flex-wrap items-center gap-2 pr-5 lg:flex-nowrap md:w-1/2 justify-start'><LocationIcon className="min-w-8" /><p className='text-xs text-left md:text-sm truncate md:max-w-48 max-w-20'>{jobListingLocation}</p></span>}
                                            {jobListingDepartment && <span className='flex flex-row flex-wrap items-center gap-2 pr-5 lg:flex-nowrap md:w-1/2 justify-start'><DeptIcon className="min-w-8"/><p className='text-xs text-left md:text-sm'>{jobListingDepartment}</p></span>}
                                        </div>
                                    </span>
                                    <Link className='cursor-pointer' to={`/detail/${job.id}`}><LinkIcon /></Link>
                                </div>
                                {(isOpen && (job.id === selectedJobId)) && (
                                    <div className='md:max-w-5xl' style={{ transition: 'all 2s ease-in' }}>
                                        <div className='' key={index}>
                                            <p className='text-left text-sm md:text-lg p-3 lg:max-w-3xl'>{job.description}</p>
                                        </div>
                                    </div>
                                )}
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};


const OverviewSection = ({ jobListings }) => {
        //Data Placeholder
        const dropdownSections = [
            {
                title: '',
                jobId: '', //JobId
                description: [''], //Description
                details: [
                {
                    id: 14,
                    text: '',
                    Department: '',
                },
                {
                    id: 15,
                    text: '',
                    Location: ''
                }
                ]
            },
    ];

    const {t} = useTranslation('translation');


    return (
        <div className='flex flex-col justify-center items-center'>
            <span>
                <p className='font-semibold text-md md:text-2xl'>{t('overviewSection.title')}</p>
            </span>
            { dropdownSections.map((section, index) => {
                const filteredJobListings = jobListings.filter(job => job.title.length > 0);
                return (
                    filteredJobListings.length > 0 && <DropdownSection key={ index } jobListings={ filteredJobListings } />
                );
            })}
        </div>
    );
};

export default OverviewSection;