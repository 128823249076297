import { useEffect, useState } from "react";
import {ReactComponent as BackIcon} from '../../icon/back.svg';
import {ReactComponent as NextIcon} from '../../icon/next.svg';
import { useTranslation } from 'react-i18next';

const ImageSlider = () => {
    const { t, i18n } = useTranslation('translation');
    

    return (
        <div className="md:w-full flex pb-20 justify-center sm:w-full px-0">
            <div className="flex flex-col max-height relative items-center justify-center">
                <video className="w-full max-w-screen-lg" src="https://www.wolfsystem.it/wp-content/uploads/2024/01/Wolf-System_Video-homepage_new-logo_01_rid.mp4" autoPlay loop muted playsInline />
            </div>
        </div>    
    );
};

export default ImageSlider;


// scrolldown btn im Slider
/* <div className="bg-[#FFCC00] cursor-pointer hover:transition-all hover:bg-[#FFE992] shadow-md py-2 px-4 h-10 w-36 absolute bottom-8">{t('imageSlider.btn')}</div> */