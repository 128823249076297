import React from 'react';
import { useTranslation } from 'react-i18next';


const SuccessMessage = ( {successMessage} ) => {

    const [t, i18n] = useTranslation('translation');

    return (
        <div className='fixed top-1/3 place-content-center flex flex-col justify-center items-center px-8 py-28 w-2/5 bg-white z-50 border-2 border-slate-150 shadow-md'>
            <p className={`text-left font-semibold ${successMessage.includes("Error") ? 'text-red-700' : 'text-emerald-600'}`}>{successMessage}.</p>
        </div>
    );
};

export default SuccessMessage;