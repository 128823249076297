import ContactForm from '../ContactForm';
import ContactFormSimple from '../ContactFormSimple';
import DetailHeroSection from './DetailHeroSection';
import DetailJobSection from './DetailJobSection';
import React from 'react';
import {ReactComponent as BackIcon} from '../../icon/back.svg';
import { Link, useParams, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';


const ListingDetailPage = ({ jobListings }) => {
    let { jobId } = useParams();
    const selectedJob = jobListings.find((job) => job.id.toString() === jobId);
    console.log(selectedJob);
    // ...
    // selectedJob is job when exists
    // selectedJob is undefined if does not exist

    if (selectedJob === undefined)
        return null;

    const isSimpleJob = selectedJob.formType === 1;

    if (!selectedJob.title) {
        return <Navigate to="/" />;
    }



    return (
        <>
            <Link id='backToHome' className='cursor-pointer' to="/">
                <div className='absolute bg-slate-950 hover:bg-slate-800 flex flex-row justify-center items-center right-10 top-7 pr-4 pl-4 h-12 md:h-14 md:px-5'>
                    <BackIcon className='md:w-3 w-2.5'/>
                </div>
            </Link>
            <div className='flex flex-col justify-center items-center w-full'>
                <DetailHeroSection job={ selectedJob } />
                <DetailJobSection job={ selectedJob } />
                { isSimpleJob ? <ContactFormSimple job={ selectedJob } /> : <ContactForm job={ selectedJob } />}
            </div>
        </>
    );
};

export default ListingDetailPage;