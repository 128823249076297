import React from 'react';
import HeroSection from './HeroSection';
import BenefitSection from './BenefitSection';
import OverviewSection from './OverviewSection';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

function HomePage({ jobListings }) {

    const [t, i18n] = useTranslation('translation');

    return (
        <div className='flex flex-col justify-center items-center w-full'>
            <HeroSection />
            <OverviewSection jobListings={ jobListings } />
            <BenefitSection />
        </div>
    );
}

export default HomePage;