import React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

function DetailJobSection({ job }) {

    const [t] = useTranslation('translation');

    const roleDetails = job.details.filter((detail) => detail.type === "Roles");
    const qualificationDetails = job.details.filter((detail) => detail.type === "Qualifications");
    const roleOfferDetails = job.details.filter((detail) => detail.type === "Offers");

    return (
        <>
            {roleDetails.length > 0 || qualificationDetails.length > 0 || roleOfferDetails.length > 0 ? (
                <div className="flex justify-center items-center w-screen mb-20 md:mt-14 bg-[#FFCC00]" >
                    <div className=' bg-white border-2 border-slate-150 shadow-md py-10 md:min-w-[600px] md:max-w-screen-sm mx-4 md:p-14 mb-24 md:-mt-14 -mt-10'>
                        <div className='flex flex-col justify-center items-center'>
                            {roleDetails.length > 0 && (
                                <div className='w-4/5'>
                                    <h3 className='text-xl text-left py-2'><b>{t('detail.roles')}</b></h3>
                                    <ul className='list-outside list-disc'>
                                        { roleDetails.map((detail) => 
                                            <li className='text-sm text-left py-1'>{ detail.text }</li>)
                                        }
                                    </ul>
                                </div>
                            )}
                            {qualificationDetails.length > 0 && (
                                <div className='w-4/5 pt-5'>
                                    <h3 className='text-xl text-left py-2'><b>{t('detail.qualifications')}</b></h3>
                                    <ul className='list-outside list-disc'>
                                        { qualificationDetails.map((detail) =>
                                            <li className='text-sm text-left py-1'>{ detail.text }</li>)
                                        }
                                    </ul>
                                </div>
                            )}
                            {roleOfferDetails.length > 0 && (
                                <div className='w-4/5 pt-5'>
                                    <h3 className='text-xl text-left py-2'><b>{t('detail.offer')}</b></h3>
                                    <ul className='list-outside list-disc'>
                                        { roleOfferDetails.map((detail) => 
                                            <li className='text-sm text-left py-1'>{ detail.text }</li>)
                                        }
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default DetailJobSection;
