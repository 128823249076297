import React, { useEffect, useState } from 'react';
import { useTranslation} from 'react-i18next';
import axios from 'axios';
import SuccessMessage from './SuccessMessage';
import  ReCAPTCHA  from "react-google-recaptcha";


const ContactForm = ({ job }) => {
    
    const { t } = useTranslation('translation');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [title, setTitle] = useState('');
    const [gender, setGender] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [address, setAddress] = useState('');
    const [cap, setCAP] = useState("");
    const [province, setProvince] = useState("");
    const [country, setCountry] = useState('ITA');
    const [reference, setReference] = useState('');
    const [reccomendation, setReccomendation] = useState('');  
    const [resume1, setResume1] = useState(null);
    const [resume1Name, setResume1Name] = useState('');
    const [resume2, setResume2] = useState(null);
    const [resume2Name, setResume2Name] = useState('');
    const [resume3, setResume3] = useState(null);
    const [resume3Name, setResume3Name] = useState('');

    const EU_COUNTRIES = ['',"Belgien", "Bulgarien", "Dänemark", "Deutschland", "Estland", "Finnland", "Frankreich", "Griechenland", "Irland", "Italien", "Kroatien", "Lettland", "Litauen", "Luxemburg", "Malta", "Niederlande", "Österreich", "Polen", "Portugal", "Rumänien", "Schweden", "Slowakei", "Slowenien", "Spanien", "Tschechien", "Ungarn", "Zypern"];
    const REFERENCES = ['', t('contact.karrieresuedtirol'), t('contact.suedtirolerjobs'), t('contact.linkedin'), t('contact.facebook'), t('contact.instagram'), t('contact.website'),  t('contact.jobprovinz'), t('contact.paper')];
    
    const TITLE = ['', 'Dr.', 'Ing.', 'Arch.', 'Mag.'];
    const genderOptions = ['', t('contact.man'), t('contact.woman'), t('contact.diverse')];
    const fileTypes = ["JPG", "JPEG", "PNG", "PDF"];

    const [files, setFiles] = useState([])

    const [provincesIt, setProvincesIt] = useState([]);
    const validationUrlIt = "https://public.wolfsystem.it/api/jobs/provinces/it";
    const [countriesIt, setCountriesIt] = useState([]);
    const validationUrl = "https://public.wolfsystem.it/api/jobs/countries/it";
    const [showPopup, setShowPopup] = useState(false);

    const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false)

    const onChange = (value) => {
        setIsCaptchaSuccess(true)
        console.log("captcha value: ", value);
    };

    const fetchProvince = async () => {
            axios.get(validationUrlIt)
            .then((res) => {
                console.log(res);
                if ((res.data !== undefined) && (res.data.length > 0))
                    setProvincesIt(res.data);
           });
    };
    const fetchCountries = async () => {
        axios.get(validationUrl)
        .then((res) => {
            console.log(res);
            if ((res.data !== undefined) && (res.data.length > 0))
                setCountriesIt(res.data);
       });
};
    useEffect(() => {
        fetchProvince();
        fetchCountries();
    }, []);

    const handleChangeResume1 = (e) => {
        let files = e.target.files;
        
        if (files.length > 1) {
            // WARNING: ONLY ABLE TO UPLOAD 1 FILE
            alert(t('contact.fileNumber'));
            return;
        }
        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 3 * 1024 * 1024) {
            // WARNING: FILE SIZE EXCEEDS 3MB
            alert(t('contact.fileSize'));
            return;
            }
            if (!fileTypes.includes(files[i].type.split('/')[1].toUpperCase())) {
            // WARNING: INVALID FILE TYPE
            alert(t('contact.fileType'));
            return;
            }
        }
        if (files[0] !== undefined) {
            setResume1(files[0]);
            setResume1Name(files[0].name);
        }
        console.log(e.target.files);
    };

    const handleChangeResume2 = (e) => {
        let files = e.target.files;
        
        if (files.length > 1) {
            // WARNING: ONLY ABLE TO UPLOAD 1 FILE
            alert(t('contact.fileNumber'));
            return;
        }
        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 3 * 1024 * 1024) {
            // WARNING: FILE SIZE EXCEEDS 3MB
            alert(t('contact.fileSize'));
            return;
            }
            if (!fileTypes.includes(files[i].type.split('/')[1].toUpperCase())) {
            // WARNING: INVALID FILE TYPE
            alert(t('contact.fileType'));
            return;
            }
        }
        if (files[0] !== undefined) {
            setResume2(files[0]);
            setResume2Name(files[0].name);
        }
        console.log(e.target.files);
    };

    const handleChangeResume3 = (e) => {
        let files = e.target.files;
        
        if (files.length > 1) {
            // WARNING: ONLY ABLE TO UPLOAD 1 FILE
            alert(t('contact.fileNumber'));
            return;
        }
        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 3 * 1024 * 1024) {
            // WARNING: FILE SIZE EXCEEDS 3MB
            alert(t('contact.fileSize'));
            return;
            }
            if (!fileTypes.includes(files[i].type.split('/')[1].toUpperCase())) {
            // WARNING: INVALID FILE TYPE
            alert(t('contact.fileType'));
            return;
            }
        }
        if (files[0] !== undefined) {
            setResume3(files[0]);
            setResume3Name(files[0].name);
        }
        console.log(e.target.files);
    };


    const url = "https://public.wolfsystem.it/api/jobs/apply";
    // const url = "https://public-test.wolfsystem.it/api/jobs/apply";

    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true); // Set isSubmitted to true on form submission
    
        function validateEmail(input) {
            return String(input)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
        function validatePhone(input, isItaly){
            var phoneRe = /^[0-9]\d{2}[0-9]\d{2}\d{3,8}$/;
            if(isItaly === true) phoneRe = /^[0-9]\d{2}[0-9]\d{2}\d{3,4}$/;
            var digits = input.replace(/\D/g, "");
            return phoneRe.test(digits);
        }
        if (
            [gender, name, surname, phone, email, province, country].some(field => field === null || field.trim() === '')
        ) {
            // Handle empty fields
            alert(t('contact.alertEmpty'));
            setIsSubmitted(false); // Set isSubmitted to true on form submission
            return;
        }
        if (resume1 === null) {
            alert(t('contact.alertResume1'));
            setIsSubmitted(false); // Set isSubmitted to true on form submission
            return;
        }
        if (email && !validateEmail(email, true)) {
            alert(t('contact.alertMail'));
            setIsSubmitted(false); // Set isSubmitted to true on form submission
            return;
        }

        if (phone && !validatePhone(phone, true)) {
            alert(t('contact.alertPhone'));
            setIsSubmitted(false); // Set isSubmitted to true on form submission
            return;
        }
        if (!isCaptchaSuccessful) {
            alert(t('contact.alertCaptcha'));
            setIsSubmitted(false); // Set isSubmitted to true on form submission
            return;
        }
        
        let formData = new FormData();
        formData.append("title", title);
        formData.append("gender", gender);
        formData.append("firstname", name);
        formData.append("lastname", surname);
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("remark", message);
        formData.append("address", address);
        formData.append("cap", cap);
        formData.append("province", province);
        formData.append("country", country);
        formData.append("source", reference);
        formData.append("suggestedBy", reccomendation);        
        formData.append("jobId", job.id);
        if(resume1 !== null) {
            formData.append("resume1Name", resume1Name);
            formData.append("resume1", resume1);
        }
        if(resume2 !== null) {
            formData.append("resume2Name", resume2Name);
            formData.append("resume2", resume2);
        }
        if(resume3 !== null) {
            formData.append("resume3Name", resume3Name);
            formData.append("resume3", resume3);
        }

        try {
            const res = await axios.post(url, formData);
            if (email.trim() !== '') {
                setShowPopup(true);
                setSuccessMessage(`${t('contact.successful')} ${email}`);
            } else {
                setShowPopup(true);
                setSuccessMessage(t('contact.successfulparticle'));
            }
            console.log('Success');
        } catch (error) {
            setShowPopup(true);
            setSuccessMessage(t('contact.failuremessage'));
            console.log('Error:', error);
            
        }
        setEmail('');
        setMessage('');
        setGender('');
        setTitle('');
        setProvince('');
        setName('');
        setSurname('');
        setPhone('');
        setAddress('');
        setCAP('');
        setProvince('');
        setCountry('');
        setReference('');
        setReccomendation('');
        setResume1(null);
        setResume1Name("");
        setResume2(null);
        setResume2Name("");
        setResume3(null);
        setResume3Name("");
        setIsSubmitted(false);
        setTimeout(() => {
            setIsSubmitted(false); 
            window.location.href = '/'; // Redirect the user to '/'
        }, 5000);
    };

    return (
        <div className='flex flex-row justify-center pb-8 md:w-full'>
            <div className=' md:flex md:flex-col md:justify-center py-10 px-6 mx-10 md:p-10 md:shadow-md md:max-w-screen-sm border-2 border-slate-150 shadow-md max-w-xs'>
                <h2 className='text-xl mb-8 font-semibold'>{t('contact.title')}</h2>
                <form className='flex flex-col justify-stretch items-center' onSubmit={handleSubmit}>
                    <span className='flex flex-col flex-wrap gap-3 py-2 md:flex-row md:flex-nowrap md:py-4 md:px-2 md:gap-6 w-full'>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.gender')}
                            <select className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' type="text" value={gender} onChange={(e) => {setGender(e.target.value)}}>
                                {genderOptions.map((gender, index) => (
                                    <option key={index} value={gender}>{gender}</option>
                                ))}
                            </select>
                        </label>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.achievement')}
                            <select className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' type="text" value={title} onChange={(e) => {setTitle(e.target.value)}}>
                                {TITLE.map((title, index) => (
                                    <option key={index} value={title}>{title}</option>
                                ))}
                            </select>
                        </label>
                    </span>
                    <span className='flex flex-col flex-wrap gap-3 py-2 md:flex-row md:flex-nowrap md:py-4 md:px-2 md:gap-6 w-full'>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.name')}
                            <input className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' type="text" value={name} onChange={(e) => setName(e.target.value)} maxLength={30}/>
                        </label>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.surname')}
                            <input className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' type="text" value={surname} onChange={(e) => setSurname(e.target.value)} maxLength={30}/>
                        </label>
                    </span>
                    <span className='flex flex-col flex-wrap gap-3 py-2 md:flex-row md:flex-nowrap md:py-4 md:px-2 md:gap-6 w-full'>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.mail')}
                            <input className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' type="text" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={50}/>
                        </label>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.phone')}
                            <input className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={50}/>
                        </label>
                    </span>
                    <span className='flex flex-col flex-wrap gap-3 py-2 md:flex-row md:flex-nowrap md:py-4 md:px-2 md:gap-6 w-full'>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.street')}
                            <input className='border-2 bg-white mt-2 md:mr-1 py-1 px-1 md:w-11/12' type="text" value={address} onChange={(e) => setAddress(e.target.value)} maxLength={100}/>
                        </label>
                        <label className='text-left flex flex-col text-sm md:ml-0 md:w-1/2'>
                            {t('contact.country')}
                            <select className='border-2 bg-white mt-2 md:mr-5 py-1 px-1 md:w-11/12' value={country.value} onChange={(e) => setCountry(e.target.value)}>
                                <option value=''>{t('contact.none')}</option>
                                {countriesIt.map((country, index) => (
                                    <option key={index} value={country.key}>{country.value}</option>
                                ))}
                            </select>
                        </label>
                    </span>
                    <span className='flex flex-col flex-wrap gap-3 py-2 md:flex-row md:flex-nowrap md:py-4 md:px-2 md:gap-6 w-full'>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.county')}
                            {country === "ITA" ? (
                                <select className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' value={province} onChange={(e) => setProvince(e.target.value)}>
                                    <option value=''>{t('contact.none')}</option>
                                    {provincesIt.map((province) => (
                                        <option key={province.key} value={province.key}>{province.value}</option>
                                    ))}
                                </select>
                            ) : (
                                <input className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' type="text" value={province} onChange={(e) => setProvince(e.target.value)} maxLength={30}/>
                            )}
                        </label>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.plz')}
                            {country === "ITA" ? (
                                <input className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' type='text' value={cap} onChange={(e) => setCAP(e.target.value.slice(0, 5))} maxLength={5} />
                            ) : (
                                <input className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' type='text' value={cap} onChange={(e) => setCAP(e.target.value)} maxLength={10} />
                            )}
                        </label>
                    </span>
                    <span className='flex flex-col flex-wrap gap-3 py-2 md:flex-row md:flex-nowrap md:py-4 md:px-2 md:gap-6 w-full'>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.reference')}
                            <select className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' value={reference} onChange={(e) => setReference(e.target.value)}>
                                {REFERENCES.map((reference, index) => (
                                    <option key={index} value={reference}>{reference}</option>
                                ))}
                            </select>
                        </label>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.reccomendation')}
                            <input className='border-2 bg-white mt-2 md:mr-5 py-1 px-1' type="text" value={reccomendation} onChange={(e) => setReccomendation(e.target.value)} maxLength={30}/>
                        </label>
                    </span>
                    <span className='flex flex-col justify-start flex-wrap gap-3 py-2 md:py-4 md:px-2 w-full'>
                        <label className='text-left flex flex-col text-sm w-full'>
                            {t('contact.comment')}
                            <textarea className='border-2 mt-2 md:mr-5 w-66 md:w-full' value={message} onChange={(e) => setMessage(e.target.value)} maxLength={500}/>
                        </label>
                        <div className='flex flex-col justify-center items-start gap-3 py-2 md:py-4 md:w-full'>
                            <span className='flex flex-row flex-wrap items-center justify-between w-full gap-5 my-5'>
                                <label className='text-left md:w-24 w-96'>{t('contact.uploadCV')}</label>
                                <input className='file:border-2 file:border-[#FFCC00] file:bg-transparent hover:transition-all 
                                file:shadow-none file:py-2 file:px-4 hover:file:border-[#FFE992]'
                                    type='file'
                                    accept={fileTypes.map((type) => `.${type}`).join(',')}                                    
                                    onChange={handleChangeResume1} />
                            </span>
                            <span className='flex flex-row flex-wrap items-center justify-between w-full gap-5 my-5'>
                                <label className='text-left md:w-24 w-96'>{t('contact.uploadLetter')}</label>
                                <input className='file:border-2 file:border-[#FFCC00] file:bg-transparent hover:transition-all 
                                file:shadow-none file:py-2 file:px-4 hover:file:border-[#FFE992]'
                                    type='file'
                                    accept={fileTypes.map((type) => `.${type}`).join(',')}                                    
                                    onChange={handleChangeResume2} />
                            </span>
                            <span className='flex flex-row flex-wrap items-center justify-between w-full gap-5 my-5'>
                                <label className='text-left md:w-24 w-96'>{t('contact.uploadOther')}</label>
                                <input className='file:border-2 file:border-[#FFCC00] file:bg-transparent hover:transition-all 
                                file:shadow-none file:py-2 file:px-4 hover:file:border-[#FFE992]'
                                    type='file'
                                    accept={fileTypes.map((type) => `.${type}`).join(',')}                                    
                                    onChange={handleChangeResume3} />
                            </span>
                        </div>
                    </span>
                    <div className='flex flex-col pt-5 justify-center items-center w-full'>
                        <div className='flex flex-row gap-2 mb-8'>
                            <p className='text-left text-sm'>{t('contact.privacy')} <a className='hover:text-neutral-400' href='https://privacy.wolfsystem.it/' target="_blank" rel="noopener noreferrer"><b>{t('contact.readprivacy')}</b></a></p>
                        </div>
                    </div>
                    <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEYSITE}
                    onChange={onChange}
                    />
                    <button className='bg-[#FFCC00] hover:transition-all hover:bg-[#FFE992] shadow-sm py-2 px-4 h-10 w-36 mt-10' type="submit" disabled={isSubmitted}>{t('contact.send')}</button>
                </form>
                <p className='flex flex-row justify-start mt-5 -mb-2 text-xs'>* {t('contact.required')}</p>
            </div>
            {showPopup === true && <SuccessMessage successMessage={successMessage} />}
        </div>
    );
};

export default ContactForm;