import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Container from './components/Container';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import React, { useEffect, useState } from 'react';
import './i18n.js'

function App() {
  const [t, i18n] = useTranslation();

  return (
    <div className="App w-full overflow-hidden">
        <Header></Header>
        <Container></Container>
        <Footer></Footer>
    </div>
  );
}

export default App;
