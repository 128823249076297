import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './HomePage/HomePage';
import DetailPage from './Detail/DetailPage';
import axios from 'axios';
import { useTranslation, i18n } from 'react-i18next'

function Container() {

    const { t, i18n } = useTranslation("translation");
    const [language, setLanguage] = useState(i18n.language);

    const url = language === 'it' ? "https://public.wolfsystem.it/api/jobs/all/it" : "https://public.wolfsystem.it/api/jobs/all/de";
    // const url = language === 'it' ? "https://public-test.wolfsystem.it/api/jobs/all/it" : "https://public-test.wolfsystem.it/api/jobs/all/de";

    const [jobListings, setJobListings] = useState([]);

    const fetchInfo = () => {
        axios.get(url)
            .then((res) => {
                console.log(res);
                if ((res.data !== undefined) && (res.data.length > 0))
                    setJobListings(res.data);
            });
    };


/*     //console.log to check if data is fetched
    console.log(jobListings); */

    useEffect(() => {
        fetchInfo();
    }, []);

    return (
        <div className=''>
            <Routes>
                <Route exact path='/' element={ <HomePage jobListings={ jobListings } /> } />
                <Route path='/detail/:jobId' element={ <DetailPage jobListings={ jobListings } /> } />
                <Route path='*' element={ <Navigate to='/' /> } />
            </Routes>
        </div>
    );
}

export default Container;
