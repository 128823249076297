import React from 'react';
import Navmenu from './Navmenu';

function Header() {
    return (
        <div className='container mx-auto static'>
                <Navmenu></Navmenu>
        </div>
    );
}

export default Header;
