import React from 'react';
import { useEffect, useState } from "react";
import ImageSlider from './ImageSlider';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

function HeroSection() {
    const [t, i18n] = useTranslation('translation');

    return (
        <div className="flex flex-col justify-center max-w-[1024px]">
            <div className="mx-auto">
               <ImageSlider />
            </div>
            <div className='flex flex-col pb-28 px-7 gap-10 justify-between sm:flex-row sm:max-md:flex-wrap md:px-8 '>
                <span className='flex flex-row gap-2'>
                <p className='font-semibold text-3xl md:text-4xl text-left'>{t('mainSection.prefix')}</p>
                <p className='font-normal text-3xl md:text-4xl text-left '>{t('mainSection.title')}</p>
                </span>
                <p className='text-left text-sm w-full'>{t('mainSection.description')}</p>
            </div>
        </div>
    );
};

export default HeroSection;