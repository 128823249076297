import React from 'react';
import {ReactComponent as LocationIcon} from '../../icon/location.svg';
import {ReactComponent as DeptIcon} from '../../icon/dept.svg';
import { useTranslation } from 'react-i18next';




function DetailHeroSection({ job }) {
    
    const [t] = useTranslation('translation');
    const getImageById = (departmentId) => {
    
    // logic to retrieve the image based on the department
    // for example, if you have an array of images with corresponding ids
    const images = [
        { department: "Fertighaus", url: require("../../img/header-3.jpg") },
        { department: "Case prefabbricate", url: require("../../img/header-3.jpg") },
        { department: "Behälterbau", url: require("../../img/header-2.jpg") },
        { department: "Vasche in cemento", url: require("../../img/header-2.jpg") },  
        { department: "Industriebau", url: require("../../img/header-4.jpg") },
        { department: "Settore industria", url: require("../../img/header-4.jpg") },
        { department: "Hallenbau", url: require("../../img/header-4.jpg") },
        { department: "Capannoni", url: require("../../img/header-4.jpg") },
        { department: "Produktion", url: require("../../img/header-5.jpg")},
        { department: "Produzione", url: require("../../img/header-5.jpg")},
        { department: "Verwaltung", url: require("../../img/header-1.jpg")},
        { department: "Amministrazione", url: require("../../img/header-1.jpg")},
        { department: "Agrarbau", url: require("../../img/header-1.jpg")},
        { department: "Settore agrario", url: require("../../img/header-1.jpg")},
        { department: "Allgemein", url: require("../../img/header-1.jpg")},
        { department: "Generale", url: require("../../img/header-1.jpg")}
    ];

    
    // find the image with the matching id
    const image = images.find((image) => image.department === departmentId);

    // return the image url
    return image ? image.url : require('../../img/header-1.jpg');
    };

    const departmentId = job.details.find(detail => detail.type === "Department")?.text;

    return (
        <div className="flex flex-col justify-center max-w-[1024px]">
            <div className="mx-auto">
                <img className='w-full pb-20' src={ departmentId ? getImageById(departmentId) : require('../../img/header-1.jpg') } alt='department' />
            </div>
                <div className='flex flex-col pb-28 gap-10 justify-between md:items-center sm:flex-row sm:max-md:flex-wrap px-8'>
                    <div className='flex flex-col gap-2'>
                        <h1 className='font-semibold text-2xl text-left opacity-65'>{ job.id }</h1>
                        <h1 className='font-semibold text-2xl text-left'>{ job.title }</h1>
                        {job.details.find(detail => detail.type === "Locations")?.text || job.details.find(detail => detail.type === "Department")?.text ? (
                            <div className='flex flex-row items-center gap-4 pt-2'>
                                {job.details.find(detail => detail.type === "Locations")?.text && (
                                    <span className='flex flex-row items-center gap-2'><LocationIcon /><p className='text-left text-sm'>{ job.details.find(detail => detail.type === "Locations")?.text }</p></span>
                                )}
                                {job.details.find(detail => detail.type === "Department")?.text && (
                                    <span className='flex flex-row items-center gap-2'><DeptIcon /><p className='text-left text-sm'>{ job.details.find(detail => detail.type === "Department")?.text }</p></span>
                                )}
                            </div>
                        ) : null}
                    </div>
                    <p className='text-left text-sm'>{ job.description }</p>
                </div>
            
        </div>
    );
};

export default DetailHeroSection;