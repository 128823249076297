import React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { t } from 'i18next';
import "./BenefitSection.css";
import {ReactComponent as BenefitOne} from '../../icon/time.svg';
import {ReactComponent as BenefitTwo} from '../../icon/education.svg';
import {ReactComponent as BenefitThree} from '../../icon/cash.svg';
import {ReactComponent as BenefitFour} from '../../icon/health.svg';
import {ReactComponent as BenefitFive} from '../../icon/food.svg';
import {ReactComponent as BenefitSix} from '../../icon/events.svg';

const componentMap = {
  BenefitOne,
  BenefitTwo,
  BenefitThree,
  BenefitFour,
  BenefitFive,
  BenefitSix
};

function BenefitSection() {
 
    const {t} = useTranslation("translation")
        
     return (
        <div className="flex flex-row justify-center lg:my-32 md:my-11 my-14 ">
            <div className='w-full'>
                <p className='font-semibold text-md md:text-2xl'>{t("benefitSection.title")}</p>
                <p className='leading-relaxed text-sm px-6'>{t("benefitSection.description")}</p>
                    <div className='flex'>
                      <div className='flex flex-row flex-wrap justify-center items-center'> 
                        {Object.keys(componentMap).map((key, index) => (
                          <div className='flex flex-col justify-start items-center my-10 mx-5 benefit-icon' key={index}>
                            {React.createElement(componentMap[key])}
                            <h3 className='my-2'>{t(`benefitSection.benefits.${key}`)}</h3>
                          </div>
                        ))}
                      </div>  
                    </div>
                </div>
            </div>
     );
};

export default BenefitSection;
