import React from 'react';
import {ReactComponent as MailIcon} from '../icon/e-mail-icon.svg';
import {ReactComponent as PhoneIcon} from '../icon/phone.svg';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'

const Links = [
    'https://www.wolfsystem.it/',
    'https://privacy.wolfsystem.it/',
];

const Footer = () => {

    const { t } = useTranslation("translation")

    return (
        <div className='flex flex-col pt-10'>
            <div className='flex flex-row flex-wrap pb-10 gap-8 justify-center items-center px-4 md:flex-nowrap'>
                <span className='flex flex-row justify-center w-64 gap-2 items-center'><PhoneIcon className='w-8' /><a href='00390472064000'><p>{t("footer.phoneNumber")}</p></a></span>
                <span className='flex flex-row justify-center w-64 gap-3 items-center'><MailIcon className='w-8' /><a href='mailto:jobs@wolfsystem.it'><p>{t("footer.email")}</p></a></span>
                
            </div>
            <div className='bg-[#FFCC00]'>
                <div className='flex flex-row w-full py-5 gap-1 justify-center'>
                    <a href={Links[0]} target="_blank" rel="noopener noreferrer">{t("footer.link1")}</a><p className='text-white font-bold'>/</p>
                    <a href={Links[1]} target="_blank" rel="noopener noreferrer">{t("footer.link2")}</a><p className='text-white font-bold'></p>
                </div>
                <div className='mb-5'>
                    <p className='text-sm text-center uppercase font-medium'>{t("footer.copyright")}</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;


