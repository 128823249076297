import React from 'react';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation, i18n } from 'react-i18next'
import i18next from 'i18next'
import mainLogo from '../img/logo.png';
import './Navmenu.css';


function Navmenu() {
    
    const { t, i18n } = useTranslation("translation")

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
        window.location.reload();
    };


    return (
        <div className='flex flex-row justify-center md:px-10 lg:py-10 py-8'>
            <div className='flex-none md:w-20 w-16'>
                <a href="https://wolfsystem.it">
                    <img src={mainLogo} alt='wolfsystem'/>
                </a>
            </div>
            <button
                className='absolute text-center left-10 top-7 md:op-8 w-12 h-12 md:w-14 md:h-14 py-2.5 border-0 appearance-none font-semibold bg-[#FFCC00] hover:bg-[#FFE992] hover:transition-all lang-select'
                onClick={() => {
                    const currentLanguage = i18next.language;
                    const oppositeLanguage = currentLanguage === "de" ? "it" : "de";
                    handleLanguageChange(oppositeLanguage);
                }}
            >
                {i18next.language === "de" ? "IT" : "DE"}
            </button>
        </div>
    )};

export default Navmenu;