import React, { useState } from 'react';
import { useTranslation} from 'react-i18next';
import axios from 'axios';
import SuccessMessage from './SuccessMessage';
import ReCAPTCHA from "react-google-recaptcha";


const ContactForm = ({ job }) => {
    
    const { t } = useTranslation('translation');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false)

    const onChange = (value) => {
        setIsCaptchaSuccess(true)
        console.log("captcha value: ", value);
    };

    const url = "https://public.wolfsystem.it/api/jobs/apply/simple";

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true); // Set isSubmitted to true on form submission

        function validateEmail(input) {
            return String(input)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
        function validatePhone(input, isItaly){
            var phoneRe = /^[0-9]\d{2}[0-9]\d{2}\d{3,8}$/;
            if(isItaly === true) phoneRe = /^[0-9]\d{2}[0-9]\d{2}\d{3,4}$/;
            var digits = input.replace(/\D/g, "");
            return phoneRe.test(digits);
        }
        if (!email && !phone) {
            alert(t('contact.alertEither'));
            setIsSubmitted(false); // Set isSubmitted to true on form submission
            return;
        }
        if (email && !validateEmail(email, true)) {
            alert(t('contact.alertMail'));
            setIsSubmitted(false); // Set isSubmitted to true on form submission
            return;
        }

        if (phone && !validatePhone(phone, true)) {
            alert(t('contact.alertPhone'));
            setIsSubmitted(false); // Set isSubmitted to true on form submission
            return;
        }
        if (!isCaptchaSuccessful) {
            alert(t('contact.alertCaptcha'));
            setIsSubmitted(false); // Set isSubmitted to true on form submission
            return;
        }
        
        let formData = new FormData();
            formData.append("firstname", name);
            formData.append("lastname", surname);
            formData.append("phone", phone);
            formData.append("email", email);      
            formData.append("jobId", job.id);

        try {
            const res = await axios.post(url, formData);
            setIsSubmitted(true); // Set isSubmitted to true on successful form submission
            if (email.trim() !== '') {
                setShowPopup(true);
                setSuccessMessage(`${t('contact.successful')} ${email}`);
            } else {
                setShowPopup(true);
                setSuccessMessage(t('contact.successfulparticle'));
            }
            console.log('Success');
        } catch (error) {
            setShowPopup(true);
            setSuccessMessage(t('contact.failuremessage'));
            console.log('Error:', error);
            
        }
            setEmail('');
            setName('');
            setSurname('');
            setPhone('');
            setTimeout(() => {
                setIsSubmitted(false); 
                window.location.href = '/'; // Redirect the user to '/'
            }, 10000);
    };

    return (
        <div className='flex flex-row justify-center pb-8 w-full'>
            <div className=' md:flex md:flex-col md:justify-center py-10 px-6 mx-10 md:p-10 md:shadow-md md:max-w-screen-sm border-2 border-slate-150 shadow-md'>
                <h2 className='text-xl mb-8 font-semibold'>{t('contact.title')}</h2>
                <form className='flex flex-col justify-stretch items-center' onSubmit={handleSubmit}>
                    <span className='flex flex-col flex-wrap gap-3 py-2 md:flex-row md:flex-nowrap md:py-4 md:px-2 md:gap-6 w-full'>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.name')}
                            <input className='border-2 bg-white mt-2 mr-5 py-1 px-1' type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        </label>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.surname')}
                            <input className='border-2 bg-white mt-2 mr-5 py-1 px-1' type="text" value={surname} onChange={(e) => setSurname(e.target.value)} />
                        </label>
                    </span>
                    <span className='flex flex-col flex-wrap gap-3 py-2 md:flex-row md:flex-nowrap md:py-4 md:px-2 md:gap-6 w-full'>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.mail')}
                            <input className='border-2 bg-white mt-2 mr-5 py-1 px-1' type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </label>
                        <label className='text-left flex flex-col text-sm md:w-1/2'>
                            {t('contact.phone')}
                            <input className='border-2 bg-white mt-2 mr-5 py-1 px-1' type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </label>
                    </span>
                    <div className='flex flex-col pt-5 justify-center items-center w-full md:mx-3'>
                        <div className='flex flex-row gap-2 mb-8 w-full md:px-2'>
                            <p className='text-left text-sm'>{t('contact.privacy')} <a className='hover:text-neutral-400' href='https://privacy.wolfsystem.it/' target="_blank" rel="noopener noreferrer"><b>{t('contact.readprivacy')}</b></a></p>
                        </div>
                    </div>
                    <ReCAPTCHA 
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEYSITE} 
                    onChange={onChange} 
                    />
                    <button className='bg-[#FFCC00] hover:transition-all hover:bg-[#FFE992] shadow-sm py-2 px-4 h-10 w-36 mt-10' type="submit" disabled={isSubmitted} >{t('contact.send')}</button>
                </form>
                <p className='flex flex-row justify-start mt-10 -mb-2 text-xs'>* {t('contact.requiredAlt')}</p>
            </div>
            {showPopup === true && <SuccessMessage successMessage={successMessage} />}
        </div>
    );
};

export default ContactForm;
